import type { Page, PageProps } from '@inertiajs/core';
import type { ComponentClass, FunctionComponent } from 'react';
import { createInertiaApp } from '@inertiajs/react';
import { FetchProvider } from 'mm-ui-kit/src/contexts/FetchContext';
import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import '../stylesheets/tailwind.css';

/**
 * Dynamically import/resolve a component by it's `name`.
 *
 * @param name a relative path from the `app/frontend/pages` directory
 * @returns
 */
function resolve(name: string) {
  const pages = import.meta.glob('../pages/**/*', { eager: false });
  return pages[`../pages/${name}`]() as Promise<{ default: FunctionComponent<PageProps> | ComponentClass<PageProps> }>;
}

/**
 * @return all of the style elements on the page which were inserted by Vite
 */
function getViteStylesheets() {
  return [
    ...document.querySelectorAll('style[data-vite-dev-id]'),
  ] as HTMLStyleElement[];
}

/**
 *
 * @param source
 */
function createStylesheet(source: HTMLStyleElement | string) {
  const sheet = new CSSStyleSheet();
  sheet.replaceSync(typeof source === 'string' ? source : source.innerHTML);

  return sheet;
}

/**
 * Extracts all of the Vite-injected stylesheets from the page and constructs stylesheet objects to be used in shadow
 * root contexts.
 *
 * @return computed stylesheets for each style element injected by Vite
 */
// eslint-disable-next-line unused-imports/no-unused-vars
const extractStylesheets = () => getViteStylesheets().map(createStylesheet);

/**
 *
 */
async function createApp(el: Element, { inertia = false } = {}) {
  if (!(el instanceof HTMLElement))
    throw new Error('Element was not an HTMLElement');

  if (!('page' in el.dataset) || typeof el.dataset.page !== 'string')
    throw new Error('`data-page` attribute must be a string');

  if (inertia) {
    await createInertiaApp({
      setup({ el, App, props }) {
        createRoot(el).render(createElement(App, props));
      },
      resolve,
    });

    return;
  }

  const page = JSON.parse(el.dataset.page) as Page<PageProps>;
  const { default: Component } = await resolve(page.component) || { default: undefined };

  if (!Component) {
    throw new Error(`${page.component} does not exist (is it in the \`frontend/pages\` directory?)`);
  }

  createRoot(el).render(
    createElement(
      FetchProvider,
      {},
      createElement(Component, page.props),
    ),
  );
}

/**
 *
 */
function bootstrap() {
  // Prevent our new styles from clobbering existing ones.
  // getViteStylesheets().forEach(el => el.disabled = true);

  const inertia = document.documentElement.classList.contains('inertia');

  return document
    .querySelectorAll('[data-page]')
    .forEach(el => createApp(el, { inertia }));
}

// @ts-expect-error Turbolinks is a browser global in Rails
document.addEventListener(typeof Turbolinks === 'undefined' ? 'DOMContentLoaded' : 'turbolinks:load', bootstrap);
